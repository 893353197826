import Image from "next/image";
import Form from "./Form";

interface InterestedInFormProps {
  data?: any;
}

export default function InterestedInForm({ data }: InterestedInFormProps) {
  return (
    <section
      id="interested-in-form"
      className="relative w-full h-[937px] py-16 px-4"
    >
      <Image
        src="/images/bg-form.png"
        alt="Background Form"
        fill
        className="object-cover object-center absolute inset-0"
        quality={95}
        loading={"lazy"}
      />
      <div className="relative z-10 h-full">
        <div className="xl:ml-[50%] mx-auto bg-white w-fit p-8 sm:p-16 max-w-[778px]">
          <h2 className="text-3xl lg:text-4xl leading-tight font-freightBig text-black mb-10">
            Interested in Facial Plastic Surgery in London?
          </h2>
          <span className="text-[#b9b9b9] text-sm block mb-10">
            Talk about your concerns with a facial cosmetic surgery expert;
            contact us today to schedule an appointment.
          </span>
          <Form />
        </div>
      </div>
    </section>
  );
}
