'use client'

import { useState } from 'react'
import { useForm } from 'react-hook-form'
import Button from "../ui/button"

type FormData = {
  firstName: string
  lastName: string
  phone: string
  email: string
  message: string
  marketing: boolean
}

export default function Form() {
  const [isLoading, setIsLoading] = useState(false)
  const [formError, setFormError] = useState<string>('')
  const [formSuccess, setFormSuccess] = useState<string>('')
  
  const { register, handleSubmit, reset, formState: { errors } } = useForm<FormData>({
    mode: 'onBlur',
  })

  const onSubmit = async (data: FormData) => {
    setIsLoading(true)
    setFormError('')
    setFormSuccess('')
    
    try {
      const response = await fetch('/api/send-interest/', {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify(data),
      })

      if (!response.ok) throw new Error()

      setFormSuccess('Thank you for your interest! We will contact you soon.')
      reset()
    } catch (error) {
      setFormError('Failed to send. Please try again.')
    } finally {
      setIsLoading(false)
    }
  }

  return (
    <form onSubmit={handleSubmit(onSubmit)} className="space-y-8">
      {formError && (
        <div className="p-3 text-sm text-red-500 bg-red-50 rounded-md">
          {formError}
        </div>
      )}
      {formSuccess && (
        <div className="p-3 text-sm text-green-500 bg-green-50 rounded-md">
          {formSuccess}
        </div>
      )}
      
      <input
        {...register('firstName', {
          required: 'First name is required',
          pattern: { value: /^[a-zA-Z\s]*$/, message: 'Only letters allowed' }
        })}
        type="text"
        placeholder="First Name"
        className="w-full border-0 placeholder:text-black font-aileron border-b border-black outline-none p-0 py-2"
      />
      {errors.firstName && (
        <p className="mt-1 text-sm text-red-500">{errors.firstName.message}</p>
      )}

      <input
        {...register('lastName', {
          required: 'Last name is required',
          pattern: { value: /^[a-zA-Z\s]*$/, message: 'Only letters allowed' }
        })}
        type="text"
        placeholder="Last Name"
        className="w-full border-0 placeholder:text-black font-aileron border-b border-black outline-none p-0 py-2"
      />
      {errors.lastName && (
        <p className="mt-1 text-sm text-red-500">{errors.lastName.message}</p>
      )}

      <input
        {...register('phone', {
          required: 'Phone number is required',
          pattern: {
            value: /^[\+]?[(]?[0-9]{3}[)]?[-\s\.]?[0-9]{3}[-\s\.]?[0-9]{4,6}$/,
            message: 'Please enter a valid phone number'
          }
        })}
        type="tel"
        placeholder="Phone"
        className="w-full border-0 placeholder:text-black font-aileron border-b border-black outline-none p-0 py-2"
      />
      {errors.phone && (
        <p className="mt-1 text-sm text-red-500">{errors.phone.message}</p>
      )}

      <input
        {...register('email', {
          required: 'Email is required',
          pattern: {
            value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
            message: 'Invalid email address'
          }
        })}
        type="email"
        placeholder="Email"
        className="w-full border-0 placeholder:text-black font-aileron border-b border-black outline-none p-0 py-2"
      />
      {errors.email && (
        <p className="mt-1 text-sm text-red-500">{errors.email.message}</p>
      )}

      <textarea
        {...register('message')}
        placeholder="Message"
        className="w-full border-0 placeholder:text-black font-aileron border-b border-black outline-none p-0 py-2"
      />

      <div className="flex justify-between items-center gap-2">
        <div>
          <input
            {...register('marketing')}
            type="checkbox"
            id="marketing"
            className="w-4 h-4 rounded border-black mr-2"
          />
          <label htmlFor="marketing" className="text-sm text-[#b9b9b9]">
            I agree to receive personalized marketing texts and/or emails
          </label>
        </div>
        <Button type="submit" variant="outline" disabled={isLoading}>
          {isLoading ? 'SENDING...' : 'SEND'}
        </Button>
      </div>
    </form>
  )
}
