import Image from "next/image";
import Link from "@/components/ui/Link";
import ReelIcon from "./ReelIcon";
import { sanitizeHTML } from "@/lib/utils";
import { getReadableImageUrl } from "@/lib/directus";

interface InstaData {
  heading?: {
    title?: string;
  };
  subheading?: {
    title?: string;
  };
  images?: {
    images_id: {
      alt: string;
      image_file: {
        image: {
          id: string;
          filename_download: string;
        };
      };
    };
  }[];
  buttons?: {
    external_links_id: {
      url: string;
      label: string;
      target: string;
      icon: {
        id: string;
        filename_download: string;
      };
    };
  }[];
}

export const fields = [
  "heading.title",
  "subheading.title",

  "images.images_id.alt",
  "images.images_id.image_file.image.id",
  "images.images_id.image_file.image.filename_download",
  
  "buttons.external_links_id.url",
  "buttons.external_links_id.target",
  "buttons.external_links_id.icon.id",
  "buttons.external_links_id.icon.filename_disk",
] as const;

export default function Insta({ data }: { data?: InstaData }) {
  return (
    <section className="py-24">
      <div className="container max-w-[1560px]">
        <h2 className="text-3xl lg:text-4xl leading-tight font-freightBig text-center mb-16"
          dangerouslySetInnerHTML={sanitizeHTML(data?.heading?.title || 'Stay Up to Date With Beauty Surgery in London')}
        />
        <div className="flex overflow-x-auto gap-4 pb-4 scrollbar-hide">
          {(data?.images || []).map((image) => (
            <Link
              key={image.images_id.image_file.image.id}
              href="#"
              className="relative group overflow-hidden bg-gray-100 flex-none w-[300px]"
            >
              <div className="absolute top-4 right-4 z-10 bg-white rounded-full p-1.5">
                <ReelIcon />
              </div>
              <Image
                src={getReadableImageUrl(image.images_id.image_file.image.id, image.images_id.image_file.image.filename_download) || ''}
                alt={image.images_id.alt || ''}
                width={300}
                height={300}
                className="w-full h-auto aspect-square object-cover transition-transform duration-300 group-hover:scale-110"
                
                quality={95}
                loading={"lazy"}
              />
            </Link>
          ))}
        </div>
        <div className="flex items-center gap-6 mt-12">
          <span className="uppercase text-sm font-bold">Follow Us</span>
          {(data?.buttons || []).map((button) => (
            <Link
              key={button.external_links_id.url}
              href={button.external_links_id.url}
              className="text-black hover:text-primary"
              aria-label={button.external_links_id.label}
              target={button.external_links_id.target || '_blank'}
            >
              <Image 
                src={getReadableImageUrl(button.external_links_id.icon.id, button.external_links_id.icon.filename_disk)}
                alt={button.external_links_id.label || ''}
                width={20}
                height={20}
                className="w-5 h-5"
                
                quality={95}
                loading={"lazy"}
              />
            </Link>
          ))}
        </div>
      </div>
    </section>
  );
}
