import Video from "@/components/shared/VideoComp/Video";
import Button from "@/components/ui/button";
import Link from "@/components/ui/Link";
import { sanitizeHTML } from "@/lib/utils";

interface MediaGalleryVideoData {
  heading?: {
    text?: string;
    title?: string;
  };
  subheading?: {
    text?: string;
    title?: string;
  };
  button?: {
    label?: string;
    href?: string;
  };
  videos?: {
    videos_id: {
      embed_url?: string;
      embed_title?: string;
    };
  }[];
  buttons?: {
    menu_items_id: {
      href: string;
      label: string;
    };
  }[];
  text?: {
    text?: string;
  };
}

export const fields = [
  "heading.text",
  "heading.title",

  "subheading.text",
  "subheading.title",

  "button.label", 
  "button.href",

  "buttons.menu_items_id.href",
  "buttons.menu_items_id.label",

  "text.text",

  "videos.videos_id.embed_url",
  "videos.videos_id.embed_title"
] as const;

export default function MediaGalleryVideo({ data }: { data: MediaGalleryVideoData }) {
  return (
    <section id="latest-facelift" className="py-14">
      <div className="max-w-[1080px] mx-auto px-4">
          <h4 
            className="font-aileron text-center font-bold text-xs uppercase tracking-[0.125rem] mb-6 text-black"
            dangerouslySetInnerHTML={sanitizeHTML(data?.subheading?.title || data?.subheading?.text || '')}
          />
        <h2 className="text-4xl md:text-5xl text-center font-freightBig mb-12">
          {data?.heading?.title}
        </h2>

        <div className="prose prose-h1:text-4xl prose-h1:font-display prose-h1:mb-8 prose-h2:text-3xl prose-h2:font-display prose-h2:mb-6 prose-h3:text-2xl prose-h3:font-display prose-h3:mb-4 prose-p:text-base prose-p:mb-4 prose-p:text-secondary-dark prose-ul:list-disc prose-ul:ml-4 prose-ul:mb-4 prose-li:mb-2 prose-a:text-primary hover:prose-a:text-primary-dark prose-a:no-underline max-w-none"
          dangerouslySetInnerHTML={sanitizeHTML(data?.text?.text || '')}
        />

        <div className="grid grid-cols-1 md:grid-cols-3 gap-14">
          {data?.videos?.map((item, index) => (
            <div key={index} className="flex flex-col">
                <iframe
                  loading={"lazy"}
                  title={item.videos_id.embed_title}
                  src={item.videos_id.embed_url}
                  allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                  allowFullScreen
                  className="size-full"
                />
            </div>
          ))}
        </div>
        {data?.button && (
          <div className="flex justify-center mt-14">
            <Button asChild>
              <Link href={data.button.href || '#'}>
                {data.button.label}
              </Link>
            </Button>
          </div>
        )}
        <div className="grid grid-cols-1 md:grid-cols-3 gap-8 mt-14">
          {data?.buttons?.map((button, index) => (
            <div key={index} className="flex justify-center">
              <Button variant="outline" asChild>
                <Link href={button?.menu_items_id?.href || ''}>
                  {button?.menu_items_id?.label}
                </Link>
              </Button>
            </div>
          ))}
        </div>
      </div>
    </section>
  );
}
