import { getReadableImageUrl } from "@/lib/directus";
import Image from "next/image";

interface ImageData {
  image: {
    alt: string;
    image_file: {
      image: {
        id: string;
        filename_download: string;
      };
    };
  };
}

interface MedicalAssociationsSectionProps {
  data: ImageData;
}

export const fields = [
  "image.alt",
  "image.image_file.image.id",
  "image.image_file.image.filename_download",
] as const;

export default function MedicalAssociationsSection({
  data,
}: MedicalAssociationsSectionProps) {
  return (
    <section id="MedicalAssociationsSection" className="py-[27px] bg-[#595959]">
      <Image
        src={getReadableImageUrl(
          data.image.image_file?.image?.id || "",
          data.image.image_file?.image?.filename_download || ""
        )}
        alt={data.image.alt || ""}
        width={1457}
        height={200}
        className="max-w-[1080px] h-auto mx-auto w-[80%]"
        quality={95}
        loading={"lazy"}
      />
    </section>
  );
}
