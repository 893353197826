import Button from "@/components/ui/button";
import Link from "@/components/ui/Link";
import Image from "next/image";
import { getReadableImageUrl } from "@/lib/directus";

interface MedicalPublicationsData {
  heading?: {
    title?: string;
  };
  images?: {
    images_id: {
      alt?: string;
      image_file?: {
        image?: {
          id?: string;
          filename_disk?: string;
        }
      }
    }
  }[];
  button?: {
    label?: string;
    href?: string;
  }
}

export const fields = [
  "heading.title",
  "images.images_id.alt",
  "images.images_id.image_file.image.id",
  "images.images_id.image_file.image.filename_download",
  "button.label",
  "button.href"
] as const;

export default function MedicalPublications({ data }: { data?: MedicalPublicationsData }) {
  return (
    <section id="latest-facelift" className="py-14">
      <div className="max-w-[1080px] mx-auto px-4">
        <h2 className="text-4xl md:text-5xl text-center font-freightBig mb-12">
          {data?.heading?.title}
        </h2>
        <div className="grid grid-cols-1 md:grid-cols-3 gap-14">
          {data?.images?.map((image, index) => (
            <div
              key={index}
              className="flex flex-col items-center justify-center min-h-[150px]"
            >
              <Image 
                width={135} 
                height={150} 
                src={getReadableImageUrl(image?.images_id?.image_file?.image?.id || '', image?.images_id?.image_file?.image?.filename_download || '')} 
                alt={image?.images_id?.alt || ''} 
                quality={95}
                loading={"lazy"}
              />
            </div>
          ))}
        </div>
        {data?.button && (
          <div className="flex justify-center mt-14">
            <Button asChild>
              <Link href={data.button.href || '#'}>{data.button.label}</Link>
            </Button>
          </div>
        )}
      </div>
    </section>
  );
}
