import { useState } from "react";
import { sanitizeHTML } from "@/lib/utils";

interface LatestFaceliftData {
  heading?: {
    title?: string;
  };
  videos?: {
    videos_id: {
      embed_url: string;
      embed_title: string;
      description?: string;
    };
  }[];
}

export const fields = [
  "heading.title",
  "videos.videos_id.embed_url",
  "videos.videos_id.embed_title",
  "videos.videos_id.description",
] as const;

export default function LatestFacelift({ data }: { data?: LatestFaceliftData }) {
  const [selectedVideo, setSelectedVideo] = useState(0);

  const videos = data?.videos || [];
  const mainVideo = videos[selectedVideo];

  return (
		<section id='latest-facelift' className='bg-black py-14'>
			<div className='max-w-[1080px] mx-auto px-4'>
				<h2
					className='text-4xl md:text-5xl text-white text-center font-freightBig mb-12'
					dangerouslySetInnerHTML={sanitizeHTML(
						data?.heading?.title || 'Latest Patient Reviews'
					)}
				/>

				<div className='flex flex-col md:flex-row gap-[59.40px]'>
					{/* Main Video Container - Left Side (~60%) */}
					<div className='w-full md:w-[60%] aspect-video bg-black'>
						{mainVideo && (
							<div className='relative w-full h-full'>
								<iframe
									loading='lazy'
									title={mainVideo.videos_id.embed_title}
									src={mainVideo.videos_id.embed_url}
									allow='accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share'
									allowFullScreen
									className='w-full h-full'
								/>
							</div>
						)}
					</div>

					{/* Right Side Container (~40%) */}
					<div className='w-full md:w-[40%] flex flex-col'>
						{/* Description */}
						{mainVideo && (
							<div
								className='text-white text-sm font-medium leading-normal mb-[30px]'
								dangerouslySetInnerHTML={sanitizeHTML(
									mainVideo.videos_id.description || ''
								)}
							/>
						)}

						{/* Thumbnail Videos - Horizontal Layout */}
						<div className='flex gap-[30px]'>
							{videos.map((video, index) => {
								// Skip the currently selected video
								if (index === selectedVideo) return null

								return (
									<button
										key={index}
										onClick={() => setSelectedVideo(index)}
										className='relative group w-[189px]'
									>
										<div className='aspect-video bg-black'>
											<iframe
												loading='lazy'
												title={video.videos_id.embed_title}
												src={video.videos_id.embed_url}
												allow='accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share'
												allowFullScreen
												className='w-full h-full pointer-events-none'
											/>
										</div>
									</button>
								)
							})}
						</div>
					</div>
				</div>
				<div className='flex justify-center mt-10'>
					<a
						className='inline-flex items-center justify-center px-[35px] py-4 text-xs font-aileron font-bold uppercase tracking-wider text-white border border-white rounded-full transition-all duration-300 hover:bg-[#645e5e] hover:text-white hover:border-black'
						href='/about/patient-testimonials/'
					>
						Watch more
					</a>
				</div>
			</div>
		</section>
	)
}
